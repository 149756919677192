// add customs styles and general overrides here
// due to the cascading nature of css, if you try to override theme css variables in this file, those changes will not apply. Instead, override css variables in the `override.sass` file
// we recommend not editing this file directly. Instead, create an `assets/sass/_custom.sass` file at the root level of your site.
// if you edit this file directly, you will have to resolve git conflicts when and if you decide to pull changes we make on the theme
strong {
	font-weight: 900;
 }	//color: red;

.my-qrcode {
	font-size: 0.90rem;
	color: #299829; }
.my-h2 {
    color: #cc2323;
    font-weight: 700;
    padding: 0;
    margin: 0; }

.sidebar_description {
    font-size: 16px;
    color: #bb4c20a3;
    margin-bottom: 30px; }

.my-title-h2 {
	color: #4e4e4ed6;
	font-weight: 900; }

.nav-link {
    color: #114e8ede;
    font-family: Microsoft YaHei; }

.button_translucent {
    background-color: rgba(55,99,124,0.15); }

.my-index-h3 {
	font-size: 1.6rem;
	font-weight: 540;
	color: #da2c2ccc; }

.my-index-pale {
	margin-top: 0.1rem;
	font-family: Microsoft YaHei;
	font-size: 1.0rem;
	padding-top: 0px; }

.my-index-pale a {
	font-family: Arial,Helvetica; }

.tags_nav {
	font-family: TimesNewRoman,Microsoft YaHei; }

.sidebar {
    max-width: 300px; }

.article_title_h1 {
	color: #da2c2ccc;
 }	//font-size: 2.5rem;

.article_title_h2 {
	color: green;
	font-weight: 900;
	font-family: Microsoft YaHei; }

#TableOfContents a {
    color: #088808;
    font-size: 0.95rem;
    font-family: Microsoft Yahei,Tahoma,PingFang SC,Lantinghei SC,Arial,Helvetica;
    margin-top: 0.05rem;
    margin-bottom: 0.05rem;
    padding-top: 0.05rem;
    padding-bottom: 0.05rem; }
// #content
// 	padding-left: 200px;
//.content
//	--maxWidth: 1840px;

.content {
  	margin: 0 0.3rem;
  	@media only screen and (min-width: 1300px) and (max-width: 1500px) {
        margin: 0 0 0 6rem; }
  	@media screen and (min-width: 1500px) and (max-width: 1750px) {
        margin: 0 0 0 11rem; }
  	@media only screen and (min-width: 1750px) {
        margin: 0 0 0 15rem; } }


blockquote p {
    padding: 0.05rem 0;
    font-size: 0.9rem; }
.content p {
	margin-bottom: 0; }

.post_content p {
    font-family: Microsoft Yahei,Tahoma,PingFang SC,Lantinghei SC,Arial,Helvetica;
    font-size: 16px;
    outline: none;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    font-weight: 400;
    color: #253535;
    line-height: 30px;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: rgba(255,255,255,0); }

.post_content blockquote p {
    font-size: 14px; }

.post_content ul li {
	font-family: Tahoma,Microsoft Yahei;
	font-size: 16px;
	color: #253535; }

.post_content ul li strong {
	font-family: Tahoma,Microsoft Yahei;
	color: #253535; }

.post_content ul li a {
	font-family: Tahoma,Microsoft Yahei;
	color: #0c5fc1; }

.post_content h1 {
	font-family: Helvetica,Arial; }

.post_content h2 {
	font-family: Microsoft YaHei,Arial,Helvetica;
	font-size: 160%;
	color: #de5708fa;
	font-weight: 800; }

.post_content h3 {
	font-family: Arial,Helvetica;
	color: #c5164ffa;
	font-size: 137%;
	font-weight: 600; }

.post_content h4 {
    font-family: Arial,Helvetica;
    color: #252525fa;
    //font-size: 137%;
    font-weight: 550; }

.post_content ul {
	font-family: Helvetica,Arial; }

.post_content table {
    font-size: 0.9rem; }

.highlight_wrap {
	margin-top: 1.0rem; }

code.noClass {
	font-family: Consolas, Liberation Mono, Menlo, Courier, monospace;
	background: rgba(0, 0, 0, 0.06);
	padding: 0 2px;
	border: 1px solid rgba(0, 0, 0, 0.08);
	border-radius: 2px 2px;
	line-height: inherit;
	font-family: Helvetica;
	color: #cc1b1b; }

.excerpt_header {
	padding-top: 0px;
	padding-bottom: 0px; }

.excerpt_thumbnail img {
	padding: 0;
	margin: 0; }

.img_alt {
    text-align: center;
    font-style: normal; }

.nav_header .google-auto-placed {
    display: none; }

pre {
    margin-bottom: 0rem; }

figure {
    margin: 0 0 0rem; }

.nav_child {
    color: #09745c; }
